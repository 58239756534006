import { TRANS_TYPES } from './constants'
import moment from 'moment-timezone'
import { get as _get } from 'lodash'
import { _contains } from 'utils/filters'

export const parseTransactions = (transactions, companyTimezone) => {
  const timezone = companyTimezone || 'Europe/London'
  return transactions.map((transaction) => {
    return {
      ...transaction,
      status: transaction.originalStatus,
      id: `${transaction.authCode}_${transaction.createdAt}`,
      transactionType: TRANS_TYPES[transaction.transactionType],
      amount: transaction.amount.toLocaleString('en-UK', { style: 'currency', currency: transaction.currency }),
      amountNumber: transaction.amount,
      remittanceId: transaction?.remittance?.id || 'NA',
      commercialLinkDisplayName: transaction?.commercialLink?.displayName || 'NA',
      date: moment.utc(transaction.createdAt).tz(timezone),
      reference: transaction.reference,
      payer: transaction.payer,
      method: transaction.method || TRANS_TYPES[transaction.transactionType] || '',
    }
  })
}

export const filterValuesToQueryParams = (filterValues, accountSelected) => {
  const filtersToSet = {
    order: 'DESC'
  }
  if (accountSelected?.currency) {
    filtersToSet.currency = accountSelected.currency
  }
  if (filterValues?.currentPage) {
    filtersToSet.page = filterValues.currentPage
  }
  if (filterValues?.itemsPerPage) {
    filtersToSet.size = filterValues.itemsPerPage
  }
  if (filterValues.transactionType && filterValues.transactionType !== 'ALL') {
    filtersToSet.transactionType = filterValues.transactionType
  }
  if (filterValues.status && filterValues.status !== 'ALL') {
    filtersToSet.status = filterValues.status
  }
  if (filterValues.date) {
    filtersToSet.startDate = moment(filterValues.date.startDate).format('YYYY-MM-DD')
    filtersToSet.endDate = moment(filterValues.date.endDate).format('YYYY-MM-DD')
  }
  if (filterValues.externalId) {
    filtersToSet.authCode = filterValues.externalId
  }

  return filtersToSet
}

export const filterLocalTransactions = (transactions, filters) => {
  return transactions.filter((transaction) => {
    if (_get(filters, 'amount.value')) {
      if (
        (filters.amount.type === 'eq' &&
          !(
            parseFloat(filters.amount.value) === transaction.amountNumber
          )) ||
        (filters.amount.type === 'gte' &&
          !(parseFloat(filters.amount.value) <= transaction.amountNumber)) ||
        (filters.amount.type === 'lte' &&
          !(parseFloat(filters.amount.value) >= transaction.amountNumber))
      ) {
        return false
      }
    }
    if (_get(filters, 'currentBalance.value')) {
      if (
        (filters.currentBalance.type === 'eq' &&
          !(parseFloat(filters.currentBalance.value) === transaction.currentBalance)) ||
        (filters.currentBalance.type === 'gte' &&
          !(parseFloat(filters.currentBalance.value) <= transaction.currentBalance)) ||
        (filters.currentBalance.type === 'lte' &&
          !(parseFloat(filters.currentBalance.value) >= transaction.currentBalance))
      ) {
        return false
      }
    }
    if (filters.remittanceId && !_contains(filters.remittanceId, transaction.remittanceId)) {
      return false
    }
    if (filters.commercialLinkDisplayName && !_contains(filters.commercialLinkDisplayName, transaction.commercialLinkDisplayName)) {
      return false
    }
    if (filters.payer && !_contains(filters.payer, transaction.payer)) {
      return false
    }
    if (filters.method && !_contains(filters.method, transaction.method)) {
      return false
    }
    if (filters.reference && !_contains(filters.reference, transaction.reference)) {
      return false
    }
    return true
  })
}
